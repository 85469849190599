import {Controller} from '@hotwired/stimulus';
import KeenDataviz from 'keen-dataviz';

export default class extends Controller {
    static values = {
        createdCount: Number,
        acceptedCount: Number,
        submittedCount: Number,
        reviewCompletedCount: Number,
    }

    connect() {
        const chart = new KeenDataviz({
            // Required:
            container: '#status_funnel', // querySelector
            title: 'Assignment funnel',
            type: "horizontal-funnel",
            funnel: {
                percents: {
                    show: true,
                    countingMethod: "relative"
                },
                lines: true,
                marginBetweenSteps: true,
                effect3d: "both-sides"
            },
            colors: [
                "#3183C8",
                "#3CAEA3",
                "#37C172",
                "#F4CA64",
                "#E46465",
                "#00cfbb",
                "#5a9eed",
                "#73d483",
                "#c879bb",
                "#0099b6",
                "#d74d58",
                "#cb9141",
                "#6b6bb6",
                "#d86945",
                "#00aa99",
                "#4281c9",
                "#57b566",
                "#ac5c9e",
                "#27cceb",
                "#ff818b",
                "#f6bf71",
                "#9b9be1",
                "#ff9b79",
                "#26dfcd",
                "#73aff4",
                "#87e096",
                "#d88bcb"
            ],
            renderOnVisible: true,
        });

        chart.render({
            query: {
                analysis_type: "funnel"
            },
            steps: [{
                event_collection: "Created"
            }, {
                event_collection: "Accepted"
            }, {
                event_collection: "Submitted"
            }, {
                event_collection: "Reviewed"
            }],
            result: [
                this.createdCountValue,
                this.acceptedCountValue,
                this.submittedCountValue,
                this.reviewCompletedCountValue
            ]
        })

    }
}
