import {Application} from "@hotwired/stimulus"
import Autocomplete from 'stimulus-autocomplete'
import controllers from "./**/*_controller.js"


export function registerStimulusControllers() {
    const application = Application.start()
    controllers.forEach((controller) => {
        application.register(controller.name, controller.module.default)
    })
    application.register('autocomplete', Autocomplete)
}