import {Controller} from '@hotwired/stimulus';
import toastr from "toastr";

export default class extends Controller {
    static targets = ["source"]

    copy() {
        navigator.clipboard.writeText(this.sourceTarget.href)
        toastr.info('Link copied to clipboard')
    }
}