import {Turbo} from "@hotwired/turbo-rails";

export function showHideProgressBarOnNavigate() {
    let adapter = Turbo.navigator.delegate.adapter;
    document.addEventListener('turbo:submit-start', function () {
        adapter.progressBar.setValue(0);
        adapter.showProgressBar();
    });


    document.addEventListener('turbo:submit-end', function () {
        adapter.hideFormProgressBar();
        adapter.progressBar.setValue(0);
    });
}