import {Controller} from "@hotwired/stimulus";
import {enter, leave} from "el-transition";


const DELAY_DURATION = 2000

function delay(duration = 1000) {
    return new Promise((res) => setTimeout(res, duration))
}

class Notification extends Controller {

    timeOutId = undefined

    initialize() {
        enter(this.element)
            .then(() => {
                return delay(DELAY_DURATION)
            })
            .then(() => leave(this.element))
            .then(() => this.element.remove())
    }

    close() {
        leave(this.element).then(() => this.element.remove())
    }
}

export default Notification