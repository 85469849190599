import {Controller} from "@hotwired/stimulus";
import toastr from "toastr";

toastr.options = {
    "debug": false,
    "closeButton": true,
    "positionClass": "toast-top-full-width",
    "onclick": null,
    "fadeIn": 300,
    "fadeOut": 1000,
    "timeOut": 5000,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

export default class extends Controller {
    static values = {
        type: String,
        message: String,
    }

    connect() {
        toastr[this.typeValue](this.messageValue)
    }
}