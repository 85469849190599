// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "bootstrap"

import 'data-confirm-modal'
import {showHideProgressBarOnNavigate} from "./initialisers/progressBar"
import {disableButtonsOnSubmit} from "./initialisers/submitButton";
import {registerStimulusControllers} from "./controllers";
import {initTooltips} from "./initialisers/tooltips";


registerStimulusControllers()
showHideProgressBarOnNavigate()
disableButtonsOnSubmit()
initTooltips()

