import {Controller} from '@hotwired/stimulus';
import $ from "jquery";

export default class extends Controller {

    connect() {
        if (this.element.dataset.close) {
            $("#modal-window").modal("hide")
        }
        if (this.element.dataset.redirect) {
            window.location = this.element.dataset.redirect;
        }
    }

    open() {
        $("#modal-window").modal()
    }
}