import Rails from "@rails/ujs";

export function disableButtonsOnSubmit() {
    Rails.start()

// Enable disable with see: https://github.com/hotwired/turbo-rails/issues/135
    Rails.delegate(document, Rails.linkDisableSelector, "turbo:before-cache", Rails.enableElement)
    Rails.delegate(document, Rails.buttonDisableSelector, "turbo:before-cache", Rails.enableElement)

    Rails.delegate(document, Rails.buttonDisableSelector, "turbo:submit-end", Rails.enableElement)
    Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-start", Rails.disableElement)
    Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-end", Rails.enableElement)

    Rails.delegate(document, Rails.formSubmitSelector, "turbo:before-cache", Rails.enableElement)
}