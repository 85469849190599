import {Controller} from '@hotwired/stimulus';
import $ from "jquery";

export default class extends Controller {
    static targets = ["selected", "input", "submit", "disabledSubmit"]

    connect() {
        $(this.disabledSubmit).tooltip()
        if (this.selectedTarget.value === "")
            this.disableSubmit()
    }

    disabledSubmitTargetDisconnected(element) {
        $(element).tooltip('dispose')
    }

    onInput() {
        this.disableSubmit();
    }

    onSelect() {
        this.enableSubmit();

    }

    enableSubmit() {
        this.disabledSubmitTarget.classList.remove("d-inline")
        this.disabledSubmitTarget.classList.add("d-none")
        this.submitTarget.classList.remove("d-none")
        this.submitTarget.classList.add("d-inline")
        this.submitTarget.disabled = false
    }

    disableSubmit() {
        this.submitTarget.classList.remove("d-inline")
        this.submitTarget.classList.add("d-none")
        this.submitTarget.disabled = true
        this.disabledSubmitTarget.classList.remove("d-none")
        this.disabledSubmitTarget.classList.add("d-inline")
    }
}